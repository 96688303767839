import { TrackingActions, SET_TRACKING_BASE, TrackingBaseData } from './types';

const initialState: TrackingBaseData = {
  trackingNumber: null,
  title: null,
  tracking: [],
  statusTransaction: 0,
  pickitNumber: '',
  retailerName: '',
  transaction: null,
  inputNumber: '',
  token: null,
};

function reducer(
  state = initialState,
  action: TrackingActions,
): TrackingBaseData {
  switch (action.type) {
    case SET_TRACKING_BASE:
      return {
        ...state,
        ...action.payload,
        tracking: action.payload.tracking.reverse(),
      };
    default:
      return state;
  }
}

export default reducer;
