import NewStepper from '@/app/components/NewStepper/NewStepper';
import { PAGE_LOAD } from '@/constants/dataLayer';
import {
  pickitNumberSelector,
  statusSelector,
  titleSelector,
  trackingNumberSelector,
  trackingSelector,
  transactionSelector,
} from '@/redux/tracking/selectors';
import { Status } from '@/redux/tracking/types';
import pushDataLayer from '@/utils/dataLayer';
import { $resolution } from '@/utils/resolution';
import Hammer from 'hammerjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styles from './liveInfo.module.scss';

const BOTTOM_OFFSET = 0;

const LiveInfo: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const sliderRef = useRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [transFactor, setTransFactor] = useState(0);
  const title = useSelector(titleSelector).split('**');
  const status = useSelector(statusSelector);
  const trackingNumber = useSelector(trackingNumberSelector);
  const pickitNumber = useSelector(pickitNumberSelector);
  const tracking = useSelector(trackingSelector);
  const transaction = useSelector(transactionSelector);
  const sliderStyles = {
    transform: !isOpen
      ? `translateY(calc(100% - ${transFactor}px))`
      : 'translateY(0)',
  };

  const onTouchStart = useCallback(() => {}, []);
  const onTouchEnd = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  const onTouchMove = useCallback(() => {}, []);
  useEffect(() => {
    pushDataLayer(PAGE_LOAD);
    if (isMobile) {
      const hammer = new Hammer(sliderRef.current);
      hammer.on('swipeup', () => {
        setIsOpen(true);
      });
      hammer.on('swipedown', () => {
        setIsOpen(false);
      });
      setTransFactor(sliderRef.current.clientHeight + 42 + BOTTOM_OFFSET);
    }
    const subscription = $resolution.subscribe((data) => {
      setIsMobile(data.isMobile());
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [isMobile]);

  if (status !== Status.Live) return <Redirect to="/" />;

  return (
    <div className={styles.liveContainer}>
      <iframe
        title="lightbox"
        src={`${process.env.VITE_LIGHTBOX_URL}/live/${pickitNumber}`}
        frameBorder="0"
      />
      {isMobile ? (
        <div style={sliderStyles} className={styles.sliderContainer}>
          <div
            ref={sliderRef}
            className={styles.titleContainer}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
          >
            <div className={styles.greyBar} />
            <div className={styles.title}>
              <h1>{title}</h1>
            </div>
          </div>
          <NewStepper
            transaction={transaction}
            trackingNumber={trackingNumber}
            steps={tracking}
          />
        </div>
      ) : (
        <>
          <div className={styles.desktopTitle}>
            <div className={styles.title}>
              <h1>{title}</h1>
            </div>
          </div>
          <div className={styles.stepperDesktop}>
            <NewStepper
              transaction={transaction}
              trackingNumber={trackingNumber}
              steps={tracking}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LiveInfo;
