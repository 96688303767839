export default async function validateRecaptcha(token: string, action: string) {
  return new Promise((res, rej) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(token, { action })
        .then((response: any) => res(response))
        .catch((error: any) => rej(error));
    });
  });
}
