import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  lng: `${process.env.VITE_LOCALE}`,
  debug: false,
  initImmediate: false,
});

export default i18next;
