import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const useGTM = () => {
  const tagManagerArgs = {
    gtmId: process.env.VITE_GTM,
  };

  useEffect(() => {
    if (
      process.env.VITE_BUILD_MODE === 'uat' ||
      process.env.VITE_BUILD_MODE === 'prod'
    ) {
      TagManager.initialize(tagManagerArgs);
    }
  });
};

export default useGTM;
