import { useCallback, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { Tracking } from '@/redux/tracking/types';
import TrackingServices from '@/services/TrackingServices';
import { getMapLink } from '@/utils/system';
import styles from '../newStepper.module.scss';
import { DescriptionProps } from '../newStepper.types';

async function obtainPointDetail(id: number) {
  try {
    const response = await TrackingServices.pointDetail(id);
    return response.data.result[0];
  } catch (error) {
    return null;
  }
}

const Description = ({ step, deliveryPromiseTime }: DescriptionProps) => {
  const [point, setPoint] = useState(null);

  useEffect(() => {
    async function obtainPointInformation() {
      const response = await obtainPointDetail(step.pointId);
      setPoint(response);
    }
    if (step.pointId) {
      obtainPointInformation();
    }
  }, [step]);

  const addTrackingText = useCallback(
    (trackingStep: Tracking, date: string) => {
      const promiseTime = '{{fecha_entrega}}';
      let text = trackingStep.description;

      if (trackingStep.description?.includes(promiseTime)) {
        text = trackingStep.description.replace(promiseTime, date);
      }

      if (point) {
        const url = getMapLink(null, point);
        const link = `<a href="${url}" target="_blank" rel="noreferrer">${point.name}</a>`;
        text = trackingStep.description.replace(point.name, link);
      }

      return text;
    },
    [point],
  );

  return (
    <>
      <p
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            addTrackingText(step, deliveryPromiseTime),
            {
              ADD_ATTR: ['target'],
            },
          ),
        }}
        className={styles.description}
      />
    </>
  );
};

export default Description;
