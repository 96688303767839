import { OperationType, Workflows } from '@/constants/states';
import {
  DISPATCH20,
  DISPATCH20_WITHDRAW_FROM_POINT,
  HOME_DISPATCH,
  HOME_REFUND,
  POINT_DISPATCH,
  POINT_REFUND,
  SAMEDAY_FOUR_STEPS,
  SAMEDAY_THREE_STEPS,
} from '@/constants/steps';
import useIsMobile from '@/hooks/useIsMobile';
import { Tracking } from '@/redux/tracking/types';
import { ComplexIcon } from '@pickit/pickit-components';
import React, { useCallback } from 'react';
import styles from './stepper.module.scss';
import { StepperProps } from './stepper.types';

const WORKFLOW_STYLES: { [key: string]: string } = {
  default: [styles.step].join(' '),
  ready: [styles.step, styles.activeStep].join(' '),
  'in-progress': [styles.step, styles.blue].join(' '),
  inconvenient: [styles.step, styles.incovenientStep].join(' '),
};

const ICON_COLOR: { [key: string]: string } = {
  default: '#B8B8B8',
  ready: '#00B2E3',
  'in-progress': '#00B2E3',
  inconvenient: '#FF1515',
};

const LINE_STYLES: { [key: string]: string } = {
  default: styles.line,
  ready: [styles.line, styles.active].join(' '),
  'in-progress': styles.line,
  inconvenient: [styles.line, styles.redLine].join(' '),
};

const TEXT_STYLES: { [key: string]: string } = {
  default: styles.text,
  ready: [styles.text, styles.blueText].join(' '),
  'in-progress': [styles.text, styles.blueText, styles.activeText].join(' '),
  inconvenient: [styles.text, styles.redText].join(' '),
};

const Stepper: React.FC<StepperProps> = ({
  workflow,
  operationType,
  steps,
  logisticSolution,
}) => {
  let stepsWf = null;
  const [isMobile] = useIsMobile();
  const isHomeRefund =
    operationType === OperationType.Home ||
    operationType === OperationType.RefundHome;
  const obtainStepper = useCallback(() => {
    const lastStep = steps.find((step: Tracking) => step.title !== '').title;
    const hasPickitPointStep = steps.find(
      (step: Tracking) => step.title === 'EN PUNTO PICKIT',
    );

    if (workflow === Workflows.SameDay) {
      const fourSteps = ['ENTREGADO', 'VENCIDO', 'EN PUNTO PICKIT'];

      return hasPickitPointStep && fourSteps.includes(lastStep)
        ? SAMEDAY_FOUR_STEPS[lastStep]
        : SAMEDAY_THREE_STEPS[lastStep];
    }

    if (workflow === Workflows.Dispatch) {
      return operationType === OperationType.Home
        ? HOME_DISPATCH[lastStep]
        : POINT_DISPATCH[lastStep];
    }

    if (workflow === Workflows.Dispatch20) {
      const dispatch20Stepper = hasPickitPointStep
        ? DISPATCH20_WITHDRAW_FROM_POINT[lastStep]
        : DISPATCH20[lastStep];

      return logisticSolution === 'multistage'
        ? dispatch20Stepper
        : HOME_DISPATCH[lastStep];
    }

    return isHomeRefund ? HOME_REFUND[lastStep] : POINT_REFUND[lastStep];
  }, [isHomeRefund, logisticSolution, operationType, steps, workflow]);

  stepsWf = obtainStepper();

  return (
    <div className={styles.wrapper}>
      {stepsWf?.map((step: any, i: number) => {
        let lineClassName =
          i === 0 && step.status === 'in-progress'
            ? [styles.line, styles.active].join(' ')
            : LINE_STYLES[step.statusLine || step.status];

        lineClassName = [
          lineClassName,
          stepsWf.length === 3 && styles.bigLine,
        ].join(' ');

        const textClassName = [
          TEXT_STYLES[step.status],
          step.active && styles.activeText,
        ].join(' ');

        if (i === stepsWf.length - 1) {
          return (
            <div className={styles.container}>
              <div
                className={[styles.stepContainer, styles.lastStep].join(' ')}
              >
                <div
                  className={[styles.stepWrapper, styles.lastItem].join(' ')}
                >
                  <div
                    className={[
                      WORKFLOW_STYLES[step.status],
                      step.active && styles.red,
                    ].join(' ')}
                  >
                    <ComplexIcon
                      name={step.icon}
                      color={ICON_COLOR[step.status]}
                      size={
                        !isMobile &&
                        (step.status === 'in-progress' || step.active)
                          ? 'small'
                          : 'extra-small'
                      }
                    />
                  </div>
                </div>
                <p className={textClassName}>{step.step}</p>
              </div>
            </div>
          );
        }

        return (
          <>
            <div className={styles.container}>
              <div
                className={[
                  styles.stepContainer,
                  (step.step === 'En punto pickit' ||
                    step.step === 'En preparación') &&
                    step.status !== 'in-progress' &&
                    !step.active &&
                    styles.withoutMargin,
                ].join(' ')}
              >
                <div className={styles.stepWrapper}>
                  <div
                    className={[
                      WORKFLOW_STYLES[step.status],
                      step.active && styles.red,
                    ].join(' ')}
                  >
                    <ComplexIcon
                      name={step.icon}
                      color={ICON_COLOR[step.status]}
                      size={
                        !isMobile &&
                        (step.status === 'in-progress' || step.active)
                          ? 'small'
                          : 'extra-small'
                      }
                    />
                  </div>
                </div>
                <p className={textClassName}>{step.step}</p>
              </div>
            </div>
            <div className={lineClassName} />
          </>
        );
      })}
    </div>
  );
};

export default Stepper;
