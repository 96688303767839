import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../styles.scss';
import { Alerts } from '@pickit/pickit-components';
import Home from './pages/Home/Home';
import '../config/api';
import Header from './components/Header/Header';
import { useTheming } from '../config/theme';
import TrackingInfo from './pages/TrackingInfo/TrackingInfo';
import { routes } from '../constants/routes';
import Loader from './components/Loader/Loader';
import LiveInfo from './pages/LiveInfo/LiveInfo';
import useGTM from '../hooks/useGTM';

const App: React.FC = () => {
  useTheming();
  useGTM();
  return (
    <Router>
      <Header />
      <Switch>
        <Route path={routes.TRACKING_INFO} exact>
          <TrackingInfo />
        </Route>
        <Route path={routes.TRACKING_LIVE_INFO} exact>
          <LiveInfo />
        </Route>
        <Route path={routes.INDEX}>
          <Home />
        </Route>
      </Switch>
      <Alerts />
      <Loader />
    </Router>
  );
};

export default App;
