import i18next from '@/config/i18next';

i18next.addResources('es', 'Header', {
  title: '¿Necesitás ayuda? Comunicate al ',
  titleMobile: '¿Necesitás ayuda? ',
  contactURL: 'https://wa.me/5491123499696/?text=¡Hola! Tengo una consulta.',
  contact: '+54 9 11 2349-9696',
});

i18next.addResources('es-AR', 'Header', {
  title: '¿Necesitás ayuda? Comunicate al ',
  titleMobile: '¿Necesitás ayuda? ',
  contactURL: 'https://wa.me/5491123499696/?text=¡Hola! Tengo una consulta.',
  contact: '+54 9 11 2349-9696',
});

i18next.addResources('es-CL', 'Header', {
  title: '¿Necesitas ayuda? Comunícate al ',
  titleMobile: '¿Necesitas ayuda? ',
  contactURL: 'https://wa.me/56958202229/?text=¡Hola! Tengo una consulta.',
  contact: '+56 9 5820 2229',
});

i18next.addResources('es-CO', 'Header', {
  title: '¿Necesitas ayuda? Comunícate al ',
  titleMobile: '¿Necesitas ayuda? ',
  contactURL: 'https://wa.me/573160171441/?text=¡Hola! Tengo una consulta.',
  contact: '+57 316 0171441',
});

i18next.addResources('es-MX', 'Header', {
  title: '¿Necesitas ayuda? Comunícate al ',
  titleMobile: '¿Necesitas ayuda? ',
  contactURL: 'https://wa.me/525532489562/?text=¡Hola! Tengo una consulta.',
  contact: '+52 55 3248 9562',
});

i18next.addResources('es-PE', 'Header', {
  title: '¿Necesitas ayuda? Comunícate al ',
  titleMobile: '¿Necesitas ayuda? ',
  contactURL: 'https://wa.me/51981828880/?text=¡Hola! Tengo una consulta.',
  contact: '+51 981 828 880',
});

i18next.addResources('es-UY', 'Header', {
  title: '¿Necesitás ayuda? Comunicate al ',
  titleMobile: '¿Necesitás ayuda? ',
  contactURL: 'mailto:contacto.uy@pickit.net',
  contact: 'contacto.uy@pickit.net',
});
