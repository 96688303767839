import { AppState } from '../store';

export const trackingNumberSelector = (state: AppState) =>
  state.tracking.trackingNumber;
export const pickitNumberSelector = (state: AppState) =>
  state.tracking.pickitNumber;
export const inputNumberSelector = (state: AppState) =>
  state.tracking.inputNumber;
export const titleSelector = (state: AppState) => state.tracking.title;
export const trackingSelector = (state: AppState) => state.tracking.tracking;
export const statusSelector = (state: AppState) =>
  state.tracking.transaction?.status;
export const transactionSelector = (state: AppState) =>
  state.tracking.transaction;
export const tokenSelector = (state: AppState) => state.tracking.token;
