import i18next from '@/config/i18next';

i18next.addResources('es', 'TooltipMessage', {
  viewMore: 'Ver detalle',
  titleCard: 'Código de seguimiento',
  tooltipDispatchHome: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchHomeMobile: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega..`,
  tooltipDispatchPoint: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retirás se lo devolvemos al vendedor. `,
  tooltipDispatchPointMobile: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retirás se lo devolvemos al vendedor. `,
  tooltipDispatch20: `<b>Cómo funciona el envío 2.0</b>

  1- Tu vendedor prepara el 
  paquete.
  2- Nosotros vamos a buscarlo y 
  te avisamos el día que lo 
  llevamos a tu casa.
  Te visitamos y si no te 
  encontramos, lo llevaremos al 
  punto pickit cercano, vas a recibir 
  un correo con la información.
  3- El paquete te espera en el 
  punto, si no lo retirás en el 
  tiempo estipulado vuelve 
  al vendedor.`,
  tooltipDispatch20Mobile: `<b>Cómo funciona el envío 2.0</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo y te avisamos el día que lo llevamos a tu casa.
  Te visitamos y si no te encontramos, lo llevaremos al punto pickit cercano, vas a recibir un correo con la información.
  3- El paquete te espera en el punto, si no lo retirás en el tiempo estipulado vuelve al vendedor.`,
  tootltipSameday: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarte si no te encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tootltipSamedayMobile: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarte si no te encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tooltipRefundHome: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, podés pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundHomeMobile: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, podés pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundPoint: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevás el paquete a un punto pickit. 
  3 - Una vez que lo dejás nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
  tooltipRefundPointMobile: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevás el paquete a un punto pickit. 
  3 - Una vez que lo dejás nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
});

i18next.addResources('es-AR', 'TooltipMessage', {
  viewMore: 'Ver detalle',
  titleCard: 'Código de seguimiento',
  tooltipDispatchHome: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchHomeMobile: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega..`,
  tooltipDispatchPoint: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retirás se lo devolvemos al vendedor. `,
  tooltipDispatchPointMobile: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retirás se lo devolvemos al vendedor. `,
  tooltipDispatch20: `<b>Cómo funciona el envío 2.0</b>

  1- Tu vendedor prepara el 
  paquete.
  2- Nosotros vamos a buscarlo y 
  te avisamos el día que lo 
  llevamos a tu casa.
  Te visitamos y si no te 
  encontramos, lo llevaremos al 
  punto pickit cercano, vas a recibir 
  un correo con la información.
  3- El paquete te espera en el 
  punto, si no lo retirás en el 
  tiempo estipulado vuelve 
  al vendedor.`,
  tooltipDispatch20Mobile: `<b>Cómo funciona el envío 2.0</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo y te avisamos el día que lo llevamos a tu casa.
  Te visitamos y si no te encontramos, lo llevaremos al punto pickit cercano, vas a recibir un correo con la información.
  3- El paquete te espera en el punto, si no lo retirás en el tiempo estipulado vuelve al vendedor.`,
  tootltipSameday: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarte si no te encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tootltipSamedayMobile: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarte si no te encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tooltipRefundHome: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, podés pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundHomeMobile: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, podés pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundPoint: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevás el paquete a un punto pickit. 
  3 - Una vez que lo dejás nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
  tooltipRefundPointMobile: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevás el paquete a un punto pickit. 
  3 - Una vez que lo dejás nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
});

i18next.addResources('es-CL', 'TooltipMessage', {
  viewMore: 'Ver detalle',
  titleCard: 'Código de seguimiento',
  tooltipDispatchHome: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchHomeMobile: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchPoint: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retiras se lo devolvemos al vendedor.`,
  tooltipDispatchPointMobile: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retiras se lo devolvemos al vendedor.`,
  tooltipDispatch20: `<b>Cómo funciona el envío 2.0</b>

  1- El vendedor prepara el 
  paquete.
  2- Nosotros vamos a buscarlo y 
  te avisamos el día que lo 
  llevamos a tu casa. 
  Te visitamos y si no te 
  encontramos, lo llevaremos al 
  punto pickit cercano, vas a recibir 
  un correo con la información.
  3- El paquete espera en el punto, 
  si no lo retiras en el tiempo 
  estipulado vuelve al vendedor.`,
  tooltipDispatch20Mobile: `<b>Cómo funciona el envío 2.0</b>
  
  1- El vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo y te avisamos el día que lo llevamos a tu casa. Te visitamos y si no te encontramos, lo llevaremos al punto pickit cercano, vas a recibir un correo con la información.
  3- El paquete espera en el punto, si no lo retiras en el tiempo estipulado vuelve al vendedor.`,
  tootltipSameday: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarlo si no lo encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tootltipSamedayMobile: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarlo si no lo encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tooltipRefundHome: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, puedes pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundHomeMobile: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, puedes pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundPoint: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevas el paquete a un punto pickit. 
  3 - Una vez que lo dejas nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
  tooltipRefundPointMobile: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevas el paquete a un punto pickit. 
  3 - Una vez que lo dejas nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
});

i18next.addResources('es-CO', 'TooltipMessage', {
  iewMore: 'Ver detalle',
  titleCard: 'Código de seguimiento',
  tooltipDispatchHome: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchHomeMobile: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchPoint: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retiras se lo devolvemos al vendedor.`,
  tooltipDispatchPointMobile: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retiras se lo devolvemos al vendedor.`,
  tooltipDispatch20: `<b>Cómo funciona el envío 2.0</b>

  1- El vendedor prepara el 
  paquete.
  2- Nosotros vamos a buscarlo y 
  te avisamos el día que lo 
  llevamos a tu casa. 
  Te visitamos y si no te 
  encontramos, lo llevaremos al 
  punto pickit cercano, vas a recibir 
  un correo con la información.
  3- El paquete espera en el punto, 
  si no lo retiras en el tiempo 
  estipulado vuelve al vendedor.`,
  tooltipDispatch20Mobile: `<b>Cómo funciona el envío 2.0</b>
  
  1- El vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo y te avisamos el día que lo llevamos a tu casa. Te visitamos y si no te encontramos, lo llevaremos al punto pickit cercano, vas a recibir un correo con la información.
  3- El paquete espera en el punto, si no lo retiras en el tiempo estipulado vuelve al vendedor.`,
  tootltipSameday: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarlo si no lo encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tootltipSamedayMobile: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarlo si no lo encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tooltipRefundHome: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, puedes pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundHomeMobile: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, puedes pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundPoint: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevas el paquete a un punto pickit. 
  3 - Una vez que lo dejas nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
  tooltipRefundPointMobile: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevas el paquete a un punto pickit. 
  3 - Una vez que lo dejas nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
});

i18next.addResources('es-MX', 'TooltipMessage', {
  viewMore: 'Ver detalle',
  titleCard: 'Código de seguimiento',
  tooltipDispatchHome: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchHomeMobile: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchPoint: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retiras se lo devolvemos al vendedor.`,
  tooltipDispatchPointMobile: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retiras se lo devolvemos al vendedor.`,
  tooltipDispatch20: `<b>Cómo funciona el envío 2.0</b>

  1- El vendedor prepara el 
  paquete.
  2- Nosotros vamos a buscarlo y 
  te avisamos el día que lo 
  llevamos a tu casa. 
  Te visitamos y si no te 
  encontramos, lo llevaremos al 
  punto pickit cercano, vas a recibir 
  un correo con la información.
  3- El paquete espera en el punto, 
  si no lo retiras en el tiempo 
  estipulado vuelve al vendedor.`,
  tooltipDispatch20Mobile: `<b>Cómo funciona el envío 2.0</b>
  
  1- El vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo y te avisamos el día que lo llevamos a tu casa. Te visitamos y si no te encontramos, lo llevaremos al punto pickit cercano, vas a recibir un correo con la información.
  3- El paquete espera en el punto, si no lo retiras en el tiempo estipulado vuelve al vendedor.`,
  tootltipSameday: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarlo si no lo encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tootltipSamedayMobile: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarlo si no lo encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tooltipRefundHome: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, puedes pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundHomeMobile: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, puedes pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundPoint: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevas el paquete a un punto pickit. 
  3 - Una vez que lo dejas nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
  tooltipRefundPointMobile: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevas el paquete a un punto pickit. 
  3 - Una vez que lo dejas nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
});

i18next.addResources('es-PE', 'TooltipMessage', {
  viewMore: 'Ver detalle',
  titleCard: 'Código de seguimiento',
  tooltipDispatchHome: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchHomeMobile: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchPoint: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retiras se lo devolvemos al vendedor.`,
  tooltipDispatchPointMobile: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retiras se lo devolvemos al vendedor.`,
  tooltipDispatch20: `<b>Cómo funciona el envío 2.0</b>

  1- El vendedor prepara el 
  paquete.
  2- Nosotros vamos a buscarlo y 
  te avisamos el día que lo 
  llevamos a tu casa. 
  Te visitamos y si no te 
  encontramos, lo llevaremos al 
  punto pickit cercano, vas a recibir 
  un correo con la información.
  3- El paquete espera en el punto, 
  si no lo retiras en el tiempo 
  estipulado vuelve al vendedor.`,
  tooltipDispatch20Mobile: `<b>Cómo funciona el envío 2.0</b>
  
  1- El vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo y te avisamos el día que lo llevamos a tu casa. Te visitamos y si no te encontramos, lo llevaremos al punto pickit cercano, vas a recibir un correo con la información.
  3- El paquete espera en el punto, si no lo retiras en el tiempo estipulado vuelve al vendedor.`,
  tootltipSameday: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarlo si no lo encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tootltipSamedayMobile: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarlo si no lo encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tooltipRefundHome: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, puedes pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundHomeMobile: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, puedes pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundPoint: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevas el paquete a un punto pickit. 
  3 - Una vez que lo dejas nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
  tooltipRefundPointMobile: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevas el paquete a un punto pickit. 
  3 - Una vez que lo dejas nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
});

i18next.addResources('es-UY', 'TooltipMessage', {
  viewMore: 'Ver detalle',
  titleCard: 'Código de seguimiento',
  tooltipDispatchHome: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega.`,
  tooltipDispatchHomeMobile: `<b>Cómo funciona el envío a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te encontramos le devolvemos el paquete al vendedor y pueden pautar una nueva entrega..`,
  tooltipDispatchPoint: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retirás se lo devolvemos al vendedor. `,
  tooltipDispatchPointMobile: `<b>Cómo funciona el envío a punto</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo al lugar de despacho y te avisamos cuando está en el punto pickit.
  3- El paquete te espera en el punto, si no lo retirás se lo devolvemos al vendedor. `,
  tooltipDispatch20: `<b>Cómo funciona el envío 2.0</b>

  1- Tu vendedor prepara el 
  paquete.
  2- Nosotros vamos a buscarlo y 
  te avisamos el día que lo 
  llevamos a tu casa.
  Te visitamos y si no te 
  encontramos, lo llevaremos al 
  punto pickit cercano, vas a recibir 
  un correo con la información.
  3- El paquete te espera en el 
  punto, si no lo retirás en el 
  tiempo estipulado vuelve 
  al vendedor.`,
  tooltipDispatch20Mobile: `<b>Cómo funciona el envío 2.0</b>

  1- Tu vendedor prepara el paquete.
  2- Nosotros vamos a buscarlo y te avisamos el día que lo llevamos a tu casa.
  Te visitamos y si no te encontramos, lo llevaremos al punto pickit cercano, vas a recibir un correo con la información.
  3- El paquete te espera en el punto, si no lo retirás en el tiempo estipulado vuelve al vendedor.`,
  tootltipSameday: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarte si no te encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tootltipSamedayMobile: `<b>Cómo funciona same day a domicilio</b>

  1 - Tu vendedor prepara el paquete.
  2 - Nosotros vamos a buscarlo al lugar de despacho y te avisamos el día que lo llevamos a tu casa. 
  3 - Te visitamos para la entrega, si no te  encontramos llevamos el paquete a un punto cercano. Luego volvemos a visitarte si no te encontramos se lo devolvemos al vendedor y pueden pautar una nueva entrega.*
  
  *Puede no tener reintentos, según el servicio contratado.`,
  tooltipRefundHome: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, podés pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundHomeMobile: `<b>Cómo funciona devoluciones a domicilio</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete.
  2 - Te visitamos y retiramos la devolución.
  3 - Si no te encontramos, podés pautar un nuevo día de retiro con el vendedor.`,
  tooltipRefundPoint: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevás el paquete a un punto pickit. 
  3 - Una vez que lo dejás nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
  tooltipRefundPointMobile: `<b>Cómo funciona devoluciones a punto</b>

  1 - Te enviamos un mail que te indica cómo preparar el paquete. 
  2 - Llevás el paquete a un punto pickit. 
  3 - Una vez que lo dejás nosotros vamos a buscarlo y se lo llevamos al vendedor.`,
});
