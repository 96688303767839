export const SET_TRACKING_BASE = '@@TRACKING/SET_TRACKING_BASE';
export interface Tracking {
  title: string;
  description: string;
  date: string;
  pointId: number;
}
export interface TrackingBaseData {
  trackingNumber: string;
  pickitNumber: string;
  tracking: Tracking[];
  title: string;
  statusTransaction: number;
  retailerName: string;
  transaction: {
    status: string;
    workflowTag: string;
    operationType: number;
    destination: any;
    origin: any;
    workflow: {
      tag: string;
    };
    logisticSolutionType: string;
    deliveryPromiseTime?: string;
  };
  inputNumber?: string;
  token?: string;
}

export enum Status {
  Pending = 'pending',
  Live = 'live',
}

type TrackingBaseAction = {
  type: typeof SET_TRACKING_BASE;
  payload: TrackingBaseData;
};

export type TrackingActions = TrackingBaseAction;
