import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import styles from './loader.module.scss';

export const $loader = new Subject();

const Loader: React.FC = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const subscription = $loader.subscribe((newShow: boolean) => {
      setShow(newShow);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return show ? (
    <div className={styles.progress}>
      <div className={styles.indeterminate} />
    </div>
  ) : null;
};

export default Loader;
