import { Steps } from './types';

export const WORKFLOW_ICON: any = {
  dispatch: {
    1: 'point',
    2: 'destination',
  },
  refund: {
    1: 'point',
    2: 'destination',
    3: 'point',
    4: 'destination',
  },
  dispatch20: {
    2: 'destination',
  },
  sameDay: {
    2: 'destination',
  },
};

// Step name
const STEP_EXPIRED = 'Vencido';
const STEP_REFUND = 'Devuelto';
const STEP_TO_REFUND = 'En devolución';
const STEP_DAMAGED = 'Siniestrado';
const STEP_CANCELED = 'Cancelado';

// Step status
const IN_PROGRESS_STATUS = 'in-progress';
const INCONVENIENT_STATUS = 'inconvenient';
const READY_STATUS = 'ready';
const DEFAULT_STATUS = 'default';

const IN_PREPARATION = {
  step: 'En preparación',
  icon: 'pending',
  status: 'default',
  active: false,
};
const ON_THE_WAY = {
  step: 'En camino',
  icon: 'truck-delivery',
  status: 'default',
  active: false,
};
const IN_PICKIT_POINT = {
  step: 'En punto pickit',
  icon: 'point',
  status: 'default',
  active: false,
};
const DELIVERED = {
  step: 'Entregado',
  icon: 'check',
  status: 'default',
  active: false,
};

const IN_POINT = {
  step: 'En punto',
  icon: 'hub-point',
  status: 'default',
  active: false,
};

const DAMAGED = {
  status: INCONVENIENT_STATUS,
  active: true,
  icon: 'error',
  step: STEP_DAMAGED,
};

// Workflows steps
// Sameday
export const SAMEDAY_THREE_STEPS: Steps = {
  'EN PREPARACIÓN': [
    { ...IN_PREPARATION, status: IN_PROGRESS_STATUS },
    { ...ON_THE_WAY },
    { ...DELIVERED },
  ],
  'EN CAMINO': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  ENTREGADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...DELIVERED, status: IN_PROGRESS_STATUS },
  ],
  'EN DEVOLUCIÓN': [
    { ...IN_PREPARATION, status: READY_STATUS },
    {
      ...ON_THE_WAY,
      step: STEP_TO_REFUND,
      status: INCONVENIENT_STATUS,
      active: true,
      statusLine: DEFAULT_STATUS,
    },
    { ...DELIVERED },
  ],
  'DEVUELTO A REMITENTE': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, step: STEP_TO_REFUND, status: INCONVENIENT_STATUS },
    {
      ...DELIVERED,
      step: STEP_REFUND,
      status: INCONVENIENT_STATUS,
      active: true,
    },
  ],
  DEVUELTO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    {
      ...ON_THE_WAY,
      step: STEP_TO_REFUND,
      status: INCONVENIENT_STATUS,
      active: true,
    },
    { ...DELIVERED },
  ],
  VENCIDO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: INCONVENIENT_STATUS },
    {
      ...DAMAGED,
      step: STEP_EXPIRED,
      status: INCONVENIENT_STATUS,
      active: true,
    },
  ],
  CANCELADA: [
    { ...IN_PREPARATION, status: INCONVENIENT_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    {
      ...DAMAGED,
      step: STEP_CANCELED,
      status: INCONVENIENT_STATUS,
      active: true,
    },
  ],
};

// Sameday con retiro en Punto
export const SAMEDAY_FOUR_STEPS: Steps = {
  'EN PUNTO PICKIT': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  ENTREGADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: READY_STATUS },
    { ...DELIVERED, status: IN_PROGRESS_STATUS },
  ],
  VENCIDO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: INCONVENIENT_STATUS },
    {
      ...DAMAGED,
      step: STEP_EXPIRED,
      status: INCONVENIENT_STATUS,
      active: true,
    },
  ],
};

// Envio a domicilio Tradicional
export const HOME_DISPATCH: Steps = {
  'EN PREPARACIÓN': [
    { ...IN_PREPARATION, status: IN_PROGRESS_STATUS },
    { ...ON_THE_WAY },
    { ...DELIVERED },
  ],
  'EN CAMINO': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  ENTREGADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...DELIVERED, status: IN_PROGRESS_STATUS },
  ],
  'NO ENTREGADO': [
    { ...IN_PREPARATION, status: READY_STATUS },
    {
      ...ON_THE_WAY,
      status: INCONVENIENT_STATUS,
      active: true,
      statusLine: DEFAULT_STATUS,
    },
    { ...DELIVERED },
  ],
  'EN DEVOLUCIÓN': [
    { ...IN_PREPARATION, status: READY_STATUS },
    {
      ...ON_THE_WAY,
      step: STEP_TO_REFUND,
      status: INCONVENIENT_STATUS,
      active: true,
      statusLine: DEFAULT_STATUS,
    },
    { ...DELIVERED, step: STEP_REFUND },
  ],
  'DEVUELTO A REMITENTE': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, step: STEP_TO_REFUND, status: INCONVENIENT_STATUS },
    {
      ...DELIVERED,
      step: STEP_REFUND,
      status: INCONVENIENT_STATUS,
      active: true,
    },
  ],
  SINIESTRADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    {
      ...ON_THE_WAY,
      status: READY_STATUS,
    },
    {
      ...DAMAGED,
    },
  ],
  CANCELADA: [
    { ...IN_PREPARATION, status: INCONVENIENT_STATUS },
    {
      ...ON_THE_WAY,
      status: INCONVENIENT_STATUS,
    },
    {
      ...DAMAGED,
      step: STEP_CANCELED,
    },
  ],
};

// Envio a Domicilio Multi Stage
export const DISPATCH20: Steps = {
  'EN PREPARACIÓN': [
    { ...IN_PREPARATION, status: IN_PROGRESS_STATUS },
    { ...ON_THE_WAY },
    { ...DELIVERED },
  ],
  'EN CAMINO': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  'POR RETIRAR': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_POINT, status: IN_PROGRESS_STATUS },
    { ...ON_THE_WAY },
    { ...DELIVERED },
  ],
  'EN CAMINO - ULTIMA MILLA': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_POINT, status: READY_STATUS },
    { ...ON_THE_WAY, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  ENTREGADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_POINT, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...DELIVERED, status: IN_PROGRESS_STATUS },
  ],
  'EN DEVOLUCIÓN': [
    { ...IN_PREPARATION, status: READY_STATUS },
    {
      ...ON_THE_WAY,
      status: INCONVENIENT_STATUS,
      active: true,
      step: STEP_TO_REFUND,
      statusLine: DEFAULT_STATUS,
    },
    { ...DELIVERED, step: STEP_REFUND },
  ],
  'DEVUELTO A REMITENTE': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS, step: STEP_TO_REFUND },
    {
      ...DELIVERED,
      step: STEP_REFUND,
      status: INCONVENIENT_STATUS,
      active: true,
    },
  ],
  'EN DEVOLUCIÓN - ULTIMA MILLA': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_POINT, status: READY_STATUS },
    {
      ...ON_THE_WAY,
      status: INCONVENIENT_STATUS,
      step: STEP_TO_REFUND,
      active: true,
      statusLine: DEFAULT_STATUS,
    },
    { ...DELIVERED },
  ],
  DEVUELTO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_POINT, status: IN_PROGRESS_STATUS },
    { ...ON_THE_WAY },
    { ...DELIVERED },
  ],
  'EN PUNTO PICKIT': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY },
    { ...IN_PICKIT_POINT, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  VENCIDO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: INCONVENIENT_STATUS },
    {
      ...DAMAGED,
      step: STEP_EXPIRED,
      status: INCONVENIENT_STATUS,
      active: true,
    },
  ],
  SINIESTRADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_POINT, status: INCONVENIENT_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    { ...DAMAGED },
  ],
  CANCELADA: [
    { ...IN_PREPARATION, status: INCONVENIENT_STATUS },
    { ...IN_POINT, status: INCONVENIENT_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    { ...DAMAGED, step: STEP_CANCELED },
  ],
};

export const DISPATCH20_WITHDRAW_FROM_POINT: Steps = {
  'EN PUNTO PICKIT': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  ENTREGADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: READY_STATUS },
    { ...DELIVERED, status: IN_PROGRESS_STATUS },
  ],
  VENCIDO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: INCONVENIENT_STATUS },
    {
      ...DAMAGED,
      step: STEP_EXPIRED,
      status: INCONVENIENT_STATUS,
      active: true,
    },
  ],
};

// Envio a Punto
export const POINT_DISPATCH: Steps = {
  'EN PREPARACIÓN': [
    { ...IN_PREPARATION, status: IN_PROGRESS_STATUS },
    { ...ON_THE_WAY },
    { ...IN_PICKIT_POINT },
    { ...DELIVERED },
  ],
  'EN CAMINO': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: IN_PROGRESS_STATUS },
    { ...IN_PICKIT_POINT },
    { ...DELIVERED },
  ],
  'EN PUNTO PICKIT': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  ENTREGADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: READY_STATUS },
    { ...DELIVERED, status: IN_PROGRESS_STATUS },
  ],
  'EN DEVOLUCIÓN': [
    { ...IN_PREPARATION, status: READY_STATUS },
    {
      ...ON_THE_WAY,
      step: STEP_TO_REFUND,
      status: INCONVENIENT_STATUS,
      active: true,
    },
    { ...DELIVERED, step: STEP_REFUND },
  ],
  'DEVUELTO A REMITENTE': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, step: STEP_TO_REFUND, status: INCONVENIENT_STATUS },
    {
      ...DELIVERED,
      step: STEP_REFUND,
      status: INCONVENIENT_STATUS,
      active: true,
    },
  ],
  'POR RETIRAR': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  VENCIDO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: INCONVENIENT_STATUS },
    {
      step: STEP_EXPIRED,
      status: INCONVENIENT_STATUS,
      active: true,
      icon: 'error',
    },
  ],
  SINIESTRADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: READY_STATUS },
    {
      ...DAMAGED,
    },
  ],
  CANCELADA: [
    { ...IN_PREPARATION, status: INCONVENIENT_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    { ...IN_PICKIT_POINT, status: INCONVENIENT_STATUS },
    {
      ...DAMAGED,
      step: STEP_CANCELED,
    },
  ],
};

// Devolución a Punto
export const POINT_REFUND: Steps = {
  'EN PREPARACIÓN': [
    { ...IN_PREPARATION, status: IN_PROGRESS_STATUS },
    { ...IN_PICKIT_POINT },
    { ...ON_THE_WAY },
    { ...DELIVERED },
  ],
  'EN PUNTO PICKIT': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: IN_PROGRESS_STATUS },
    { ...ON_THE_WAY },
    { ...DELIVERED },
  ],
  'EN CAMINO': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: READY_STATUS },
    { ...ON_THE_WAY, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  ENTREGADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...DELIVERED, status: IN_PROGRESS_STATUS },
  ],
  'IMPOSIBLE DE RETIRO': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: READY_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    { ...DAMAGED, step: 'No retirado' },
  ],
  VENCIDO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: INCONVENIENT_STATUS },
    { ...DAMAGED, step: STEP_EXPIRED },
  ],
  SINIESTRADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...IN_PICKIT_POINT, status: INCONVENIENT_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    { ...DAMAGED },
  ],
  CANCELADA: [
    { ...IN_PREPARATION, status: INCONVENIENT_STATUS },
    { ...IN_PICKIT_POINT, status: INCONVENIENT_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    { ...DAMAGED, step: STEP_CANCELED },
  ],
};

// Devolución a Domicilio
export const HOME_REFUND: Steps = {
  'EN PREPARACIÓN': [
    { ...IN_PREPARATION, status: IN_PROGRESS_STATUS },
    { ...ON_THE_WAY },
    { ...DELIVERED },
  ],
  'EN CAMINO': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: IN_PROGRESS_STATUS },
    { ...DELIVERED },
  ],
  ENTREGADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: READY_STATUS },
    { ...DELIVERED, status: IN_PROGRESS_STATUS },
  ],
  VENCIDO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    { ...DAMAGED, step: STEP_EXPIRED },
  ],
  SINIESTRADO: [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    { ...DAMAGED },
  ],
  'IMPOSIBLE DE RETIRO': [
    { ...IN_PREPARATION, status: READY_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    { ...DAMAGED, step: 'No retirado' },
  ],
  CANCELADA: [
    { ...IN_PREPARATION, status: INCONVENIENT_STATUS },
    { ...ON_THE_WAY, status: INCONVENIENT_STATUS },
    { ...DAMAGED, step: STEP_CANCELED },
  ],
};
