import { BasicIcon, Tooltip, ToolTipPosition } from '@pickit/pickit-components';
import moment from 'moment';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Workflows,
  WorkflowTooltips,
  WorkflowTooltipsMobile,
} from '../../../constants/states';
import useIsMobile from '../../../hooks/useIsMobile';
import Stepper from '../Stepper/Stepper';
import Description from './components/Description';
import styles from './newStepper.module.scss';
import { VerticalStepperProps } from './newStepper.types';
import './tooltipMessage.i18n';

moment.locale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
});

const NewStepper: React.FC<VerticalStepperProps> = ({ steps, transaction }) => {
  const [t] = useTranslation();
  const [isMobile] = useIsMobile();
  const {
    operationType,
    logisticSolutionType,
    deliveryPromiseTime,
  } = transaction;
  const tag = transaction.workflowTag;

  const toolTipMessage = useCallback(() => {
    if (tag === Workflows.Dispatch || tag === Workflows.Refund) {
      return isMobile
        ? WorkflowTooltipsMobile[tag][operationType]
        : WorkflowTooltips[tag][operationType];
    }

    return isMobile ? WorkflowTooltipsMobile[tag] : WorkflowTooltips[tag];
  }, [isMobile, operationType, tag]);

  const containerRef = useRef<HTMLDivElement>(null);
  const message = toolTipMessage();

  return (
    <div>
      <Stepper
        logisticSolution={logisticSolutionType}
        steps={steps}
        workflow={tag}
        operationType={operationType}
      />
      <div className={styles.state}>
        <p className={styles.stateTitle}>Estado de tu envio</p>
        <Tooltip
          position={
            isMobile ? ToolTipPosition.bottom : ToolTipPosition.bottomRight
          }
          message={t(`TooltipMessage:${message}`)}
        >
          <div className={styles.stateIcon}>
            <BasicIcon
              color="#338AF3"
              name="information-circle"
              size="extra-small"
            />
          </div>
        </Tooltip>
      </div>
      <div ref={containerRef} className={styles.vStepper}>
        {steps.map((step) => (
          <div
            key={`${step.date}_${step.title}`}
            className={styles.stepDescription}
          >
            <Description
              step={step}
              deliveryPromiseTime={deliveryPromiseTime}
            />
            <span className={styles.date}>
              {moment(step.date, 'DD/MM/YYYY hh:mm:ss').format(
                'DD/MM/YYYY H:mm',
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewStepper;
