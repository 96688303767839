import arFlag from '../app/assets/flags/ar.svg';
import clFlag from '../app/assets/flags/cl.svg';
import coFlag from '../app/assets/flags/co.svg';
import mxFlag from '../app/assets/flags/mx.svg';
import peFlag from '../app/assets/flags/pe.svg';
import uyFlag from '../app/assets/flags/uy.svg';

export const flags: { [key: string]: string } = {
  AR: arFlag,
  CL: clFlag,
  CO: coFlag,
  MX: mxFlag,
  PE: peFlag,
  UY: uyFlag,
};
