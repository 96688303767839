import i18next from '@/config/i18next';

i18next.addResources('es', 'Home', {
  pickitCode: 'Ingresá tu código de seguimiento',
  genericError: 'Ha ocurrido un error, por favor vuelta a intentar',
  requiredInput: 'Este campo es requerido',
  welcome: 'Seguí tu paquete desde donde estés',
  state: 'Consultá el estado de tu pedido las 24hs',
  firstWelcomeParaFirstPart: 'Ingresá tu',
  firstWelcomeParaBold: 'código de seguimiento',
  firstWelcomeParaLastPart: 'para saber donde está tu pedido.',
  secondWelcomePara:
    'Podés encontrarlo en el mail que recibiste cuando hiciste tu compra.',
  placeHolder: 'Ingresá tu código de seguimiento',
  hotjarSiteId: '2297548',
  hotjarVersion: '6',
  termsAndPrivacyFirstText:
    'Este sitio está protegido por reCAPTCHA y Google. Al continuar estás aceptando los ',
  termsAndPrivacySecondText: ' y la ',
  terms: 'Términos del servicio',
  privacy: 'Política de privacidad',
  inactiveTitle: 'Tenemos un problema con tu paquete',
  inactiveText: `Por favor, comunicate con {{retailer}}
  para saber como continuar con el proceso.`,
  inactiveButton: 'Entendido',
});

i18next.addResources('es-AR', 'Home', {
  pickitCode: 'Ingresá tu código de seguimiento',
  genericError: 'Ha ocurrido un error, por favor vuelta a intentar',
  requiredInput: 'Este campo es requerido',
  welcome: 'Seguí tu paquete desde donde estés',
  state: 'Consultá el estado de tu pedido las 24hs',
  firstWelcomeParaFirstPart: 'Ingresá tu',
  firstWelcomeParaBold: 'código de seguimiento',
  firstWelcomeParaLastPart: 'para saber donde está tu pedido.',
  secondWelcomePara:
    'Podés encontrarlo en el mail que recibiste cuando hiciste tu compra.',
  placeHolder: 'Ingresá tu código de seguimiento',
  hotjarSiteId: '2297548',
  hotjarVersion: '6',
  termsAndPrivacyFirstText:
    'Este sitio está protegido por reCAPTCHA y Google. Al continuar estás aceptando los ',
  termsAndPrivacySecondText: ' y la ',
  terms: 'Términos del servicio',
  privacy: 'Política de privacidad',
  inactiveTitle: 'Tenemos un problema con tu paquete',
  inactiveText: `Por favor, comunicate con {{retailer}}
  para saber como continuar con el proceso.`,
  inactiveButton: 'Entendido',
});

i18next.addResources('es-CL', 'Home', {
  pickitCode: 'Ingresá tu código de seguimiento',
  genericError: 'Ha ocurrido un error, por favor vuelta a intentar',
  requiredInput: 'Este campo es requerido',
  welcome: 'Sigue tu paquete donde te encuentres',
  state: 'Consultá el estado de tu pedido las 24hs',
  firstWelcomeParaFirstPart: 'Ingresa tu',
  firstWelcomeParaBold: 'código de seguimiento',
  firstWelcomeParaLastPart: 'para saber donde se encuentra tu pedido.',
  secondWelcomePara:
    'Puedes encontrarlo en el mail que recibiste cuando realizaste tu compra.',
  placeHolder: 'Ingresa tu código de seguimiento',
  hotjarSiteId: '2297548',
  hotjarVersion: '6',
  termsAndPrivacyFirstText:
    'Este sitio está protegido por reCAPTCHA y Google. Al continuar estás aceptando los ',
  termsAndPrivacySecondText: ' y la ',
  terms: 'Términos del servicio',
  privacy: 'Política de privacidad',
  inactiveTitle: 'Tenemos un problema con tu paquete',
  inactiveText: `Por favor, comunicate con {{retailer}}
  para saber como continuar con el proceso.`,
  inactiveButton: 'Entendido',
});

i18next.addResources('es-CO', 'Home', {
  pickitCode: 'Ingresá tu código de seguimiento',
  genericError: 'Ha ocurrido un error, por favor vuelta a intentar',
  requiredInput: 'Este campo es requerido',
  welcome: 'Sigue tu paquete donde te encuentres',
  state: 'Consultá el estado de tu pedido las 24hs',
  firstWelcomeParaFirstPart: 'Ingresa tu',
  firstWelcomeParaBold: 'código de seguimiento',
  firstWelcomeParaLastPart: 'para saber donde se encuentra tu pedido.',
  secondWelcomePara:
    'Puedes encontrarlo en el mail que recibiste cuando realizaste tu compra.',
  placeHolder: 'Ingresa tu código de seguimiento',
  hotjarSiteId: '2297548',
  hotjarVersion: '6',
  termsAndPrivacyFirstText:
    'Este sitio está protegido por reCAPTCHA y Google. Al continuar estás aceptando los ',
  termsAndPrivacySecondText: ' y la ',
  terms: 'Términos del servicio',
  privacy: 'Política de privacidad',
  inactiveTitle: 'Tenemos un problema con tu paquete',
  inactiveText: `Por favor, comunicate con {{retailer}}
  para saber como continuar con el proceso.`,
  inactiveButton: 'Entendido',
});

i18next.addResources('es-MX', 'Home', {
  pickitCode: 'Ingresá tu código de seguimiento',
  genericError: 'Ha ocurrido un error, por favor vuelta a intentar',
  requiredInput: 'Este campo es requerido',
  welcome: 'Sigue tu paquete donde te encuentres',
  state: 'Consultá el estado de tu pedido las 24hs',
  firstWelcomeParaFirstPart: 'Ingresa tu',
  firstWelcomeParaBold: 'código de seguimiento',
  firstWelcomeParaLastPart: 'para saber donde se encuentra tu pedido.',
  secondWelcomePara:
    'Puedes encontrarlo en el mail que recibiste cuando realizaste tu compra.',
  placeHolder: 'Ingresa tu código de seguimiento',
  hotjarSiteId: '2297548',
  hotjarVersion: '6',
  termsAndPrivacyFirstText:
    'Este sitio está protegido por reCAPTCHA y Google. Al continuar estás aceptando los ',
  termsAndPrivacySecondText: ' y la ',
  terms: 'Términos del servicio',
  privacy: 'Política de privacidad',
  inactiveTitle: 'Tenemos un problema con tu paquete',
  inactiveText: `Por favor, comunicate con {{retailer}}
  para saber como continuar con el proceso.`,
  inactiveButton: 'Entendido',
});

i18next.addResources('es-PE', 'Home', {
  pickitCode: 'Ingresá tu código de seguimiento',
  genericError: 'Ha ocurrido un error, por favor vuelta a intentar',
  requiredInput: 'Este campo es requerido',
  welcome: 'Sigue tu paquete donde te encuentres',
  state: 'Consultá el estado de tu pedido las 24hs',
  firstWelcomeParaFirstPart: 'Ingresa tu',
  firstWelcomeParaBold: 'código de seguimiento',
  firstWelcomeParaLastPart: 'para saber donde se encuentra tu pedido.',
  secondWelcomePara:
    'Puedes encontrarlo en el mail que recibiste cuando realizaste tu compra.',
  placeHolder: 'Ingresa tu código de seguimiento',
  hotjarSiteId: '2297548',
  hotjarVersion: '6',
  termsAndPrivacyFirstText:
    'Este sitio está protegido por reCAPTCHA y Google. Al continuar estás aceptando los ',
  termsAndPrivacySecondText: ' y la ',
  terms: 'Términos del servicio',
  privacy: 'Política de privacidad',
  inactiveTitle: 'Tenemos un problema con tu paquete',
  inactiveText: `Por favor, comunicate con {{retailer}}
  para saber como continuar con el proceso.`,
  inactiveButton: 'Entendido',
});

i18next.addResources('es-UY', 'Home', {
  pickitCode: 'Ingresá tu código de seguimiento',
  genericError: 'Ha ocurrido un error, por favor vuelta a intentar',
  requiredInput: 'Este campo es requerido',
  welcome: 'Seguí tu paquete desde donde estés',
  state: 'Consultá el estado de tu pedido las 24hs',
  firstWelcomeParaFirstPart: 'Ingresá tu',
  firstWelcomeParaBold: 'código de seguimiento',
  firstWelcomeParaLastPart: 'para saber donde está tu pedido.',
  secondWelcomePara:
    'Podés encontrarlo en el mail que recibiste cuando hiciste tu compra.',
  placeHolder: 'Ingresá tu código de seguimiento',
  hotjarSiteId: '2297548',
  hotjarVersion: '6',
  termsAndPrivacyFirstText:
    'Este sitio está protegido por reCAPTCHA y Google. Al continuar estás aceptando los ',
  termsAndPrivacySecondText: ' y la ',
  terms: 'Términos del servicio',
  privacy: 'Política de privacidad',
  inactiveTitle: 'Tenemos un problema con tu paquete',
  inactiveText: `Por favor, comunicate con {{retailer}}
  para saber como continuar con el proceso.`,
  inactiveButton: 'Entendido',
});
