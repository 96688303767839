/* eslint-disable react/jsx-one-expression-per-line */
import { $alerts, Button } from '@pickit/pickit-components';
import React, {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Banners } from '../../../constants/banners';
import {
  CHECK_STATUS,
  NOT_FOUND,
  PAGE_LOAD,
} from '../../../constants/dataLayer';
import { routes } from '../../../constants/routes';
import useHotjar from '../../../hooks/useHotjar';
import useIsMobile from '../../../hooks/useIsMobile';
import useRecaptchaV3 from '../../../hooks/useRecaptchaV3';
import { setBaseTracking } from '../../../redux/tracking/actions';
import { Status } from '../../../redux/tracking/types';
import TrackingServices from '../../../services/TrackingServices';
import pushDataLayer from '../../../utils/dataLayer';
import validateRecaptcha from '../../../utils/recaptcha';
import Banner from '../../components/Banner/Banner';
import { $loader } from '../../components/Loader/Loader';
import './home.i18n';
import styles from './home.module.scss';
import { HomeProps } from './home.types';

const Home: React.FC<HomeProps> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [hasError, setHasError] = useState(false);
  const match = useRouteMatch<{ pickitCode: string }>();
  const retailerToken = new URLSearchParams(location.search).get('token');
  const history = useHistory();
  const [pickitCode, setPickitCode] = useState(
    match.params.pickitCode ? decodeURIComponent(match.params.pickitCode) : '',
  );
  const [dirty, setDirty] = useState(!!pickitCode);
  const [t] = useTranslation();
  const [isMobile] = useIsMobile();
  useRecaptchaV3();

  useEffect(() => {
    pushDataLayer(PAGE_LOAD);
  }, []);

  const onBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    setDirty(!!event.target.value);
  }, []);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setHasError(true);
    } else {
      setHasError(false);
    }
    setPickitCode(event.target.value);
    setDirty(!!event.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLElement>) => {
      event.preventDefault();

      if (!dirty) {
        return;
      }

      const gRecaptchaToken = await validateRecaptcha(
        process.env.VITE_RECAPTCHA_KEY_V3,
        'submit',
      );
      const recaptchaToken = gRecaptchaToken.toString();
      pushDataLayer({
        ...CHECK_STATUS,
        tracking_id: pickitCode,
      });

      try {
        $loader.next(true);
        const response = await TrackingServices.base(
          encodeURIComponent(pickitCode.replace(/\s/g, '')),
          recaptchaToken,
          retailerToken,
        );

        $loader.next(false);
        if (!response.data) {
          $alerts.next({
            type: 'error',
            title: response.headers
              ? decodeURIComponent(escape(response.headers['context-message']))
              : t('Home:genericError'),
            onClose: () => setPickitCode(''),
          });

          return;
        }

        if (!response.data.title) {
          $alerts.next({
            title: t('TrackingInfo:noStateTitle'),
            type: 'warning',
            subMessage: t('TrackingInfo:noStateMessage'),
            onConfirmLabel: t('TrackingInfo:noStateLabel'),
            onClose: () => setPickitCode(''),
          });
          return;
        }

        dispatch(
          setBaseTracking({
            ...response.data,
            inputNumber: pickitCode,
            token: retailerToken,
          }),
        );

        if (response.data.transaction.status === Status.Live) {
          history.push(routes.TRACKING_LIVE_INFO);
        } else {
          history.push(routes.TRACKING_INFO);
        }
      } catch (error) {
        const { response } = error;
        pushDataLayer(NOT_FOUND);

        $loader.next(false);

        $alerts.next({
          type: response?.status === 400 ? 'info' : 'error',
          title: response.data?.context ?? t('Home:genericError'),
          subMessage: response.data?.message ?? '',
          onClose: () => {
            setPickitCode('');
            setDirty(false);
          },
          onConfirmLabel: t('Home:inactiveButton'),
        });
      }
    },
    [dirty, pickitCode, retailerToken, dispatch, t, history],
  );
  useHotjar('Home');
  return (
    <div className={styles.home} data-testid="home-id">
      <div className={styles.welcome}>
        <h1>{t<string>('Home:welcome')}</h1>
        <p>
          {`${t('Home:firstWelcomeParaFirstPart')} `}
          <strong>{t<string>('Home:firstWelcomeParaBold')}</strong>
          {` ${t('Home:firstWelcomeParaLastPart')} `}
          <strong>{t<string>('Home:secondWelcomePara')}</strong>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputContainer}>
          <input
            className={dirty && hasError ? styles.inputError : ''}
            onBlur={onBlur}
            value={pickitCode}
            placeholder={t('Home:placeHolder')}
            onChange={handleChange}
            data-testid="input-id"
          />
        </div>
        {dirty && hasError && (
          <div className={styles.errorContainer}>
            <span className={styles.errorMessage}>
              {t<string>('Home:requiredInput')}
            </span>
          </div>
        )}
        <div className={styles.recaptchaTextContainer}>
          <p>
            {t<string>('Home:termsAndPrivacyFirstText')}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t<string>('Home:terms')}
            </a>
            {t<string>('Home:termsAndPrivacySecondText')}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t<string>('Home:privacy')}
            </a>
            .
          </p>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            fullWidth={isMobile}
            type="submit"
            label="Buscar"
            disabled={!dirty || hasError}
          />
        </div>
      </form>
      <div className={styles.bannerContainer}>
        <Banner bannerTag={Banners.initial} retailerToken={retailerToken} />
        <a
          href={`${t<string>('Header:contactURL')}`}
          className={styles.helpLink}
        >
          {t<string>('Header:title')} {t<string>('Header:contact')}
        </a>
      </div>
    </div>
  );
};

export default Home;
