import api from '../config/api';
import { TrackingBaseData } from '../redux/tracking/types';
import { SubscriptionData } from './types';

const TrackingServices = {
  base: (pickitNumber: string, recaptchaToken: string, retailerToken: string) =>
    api.get<TrackingBaseData>(`/publicApiV2/tracking/base/${pickitNumber}`, {
      headers: { Grecaptcharesponse: recaptchaToken },
      params: {
        token: retailerToken,
      },
    }),
  pointDetail: (pointId: number) =>
    api.get('/publicApiV2/point/detail', {
      params: {
        'filter.base.ids': pointId,
        'filter.coordinates': true,
      },
    }),
  subscription: (data: SubscriptionData) =>
    api.post('/publicApiV2/subscription/', data),
  banner: (bannerTag: string, retailerToken: string) =>
    api.get(`/publicApiV2/cms/${bannerTag}`, {
      params: { retailerToken },
    }),
};

export default TrackingServices;
