import TagManager from 'react-gtm-module';
import { DataLayer } from '../constants/types';

const pushDataLayer = (dataLayerEvent: DataLayer) => {
  const dataLayer = {
    dataLayer: dataLayerEvent,
  };

  TagManager.dataLayer(dataLayer);
};

export default pushDataLayer;
