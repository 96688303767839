import React from 'react';
import { flags } from '../../../constants/countries';
import logo from '../../assets/pickit-logo-desktop.svg';
import logoMobile from '../../assets/pickit-logo-mobile.svg';
import styles from './header.module.scss';
import './header.i18n';

const Header: React.FC = () => (
  <section className={styles.header}>
    <picture>
      <source
        className={styles.logo}
        media="(max-width: 780px)"
        srcSet={logoMobile}
      />
      <img className={styles.logo} src={logo} alt="LOGO PICKIT" />
    </picture>

    <div className={styles.title}>
      <img src={flags[process.env.VITE_COUNTRY]} alt="" />
    </div>
  </section>
);

export default Header;
