import { BehaviorSubject } from 'rxjs';

const getMobileDetect = (userAgent: string, windowWidth: number) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));

  const isMobile = () =>
    Boolean(
      isAndroid() || isIos() || isOpera() || isWindows() || windowWidth < 768,
    );
  const isDesktop = () => Boolean(!isMobile() && !isSSR());
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  };
};

export const $resolution = new BehaviorSubject(
  getMobileDetect(navigator.userAgent, window.innerWidth),
);

window.addEventListener('resize', () => {
  $resolution.next(getMobileDetect(navigator.userAgent, window.innerWidth));
});
