import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';

const useHotjar = (view: string) => {
  const [t] = useTranslation();
  const hotjarActive = true;
  const hotjarSiteId = t(`${view}:hotjarSiteId`);
  const hotjatVersion: number = Number(t(`${view}:hotjarVersion`));
  useEffect(() => {
    if (hotjarActive) hotjar.initialize(Number(hotjarSiteId), hotjatVersion);
  });
};

export default useHotjar;
