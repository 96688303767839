import { DataLayer } from './types';

const PAGE_LOAD: DataLayer = {
  event: 'trackContentGroup',
  content_group: 'Tracking',
};

const CHECK_STATUS: DataLayer = {
  event: 'check_order_status',
  tracking_id: '',
};

const NOT_FOUND: DataLayer = {
  event: 'notFound_trackingId',
};

export { PAGE_LOAD, CHECK_STATUS, NOT_FOUND };
