import i18next from '@/config/i18next';

i18next.addResources('es', 'TrackingInfo', {
  emailPlaceholder: 'Email',
  subtitle: 'Código de seguimiento ',
  buttonLabel: 'Consultar otro envío',
  noStateLabel: 'Entendido',
  noStateTitle: 'Tu paquete no comenzó el recorrido',
  noStateMessage: 'Por favor, intentá nuevamente más tarde.',
  subscribeTitle: '¡Suscribite!',
  subscribeMessage: 'Recibí todas las actualizaciones de tu pedido',
  privacyPolicy: `Seleccionando "Enviar" estás aceptando los términos y condiciones del servicio`,
  successMessage: '¡Te suscribiste correctamente!',
  successInfo:
    'A partir de ahora recibirás todas las actualizaciones de tu pedido',
});

i18next.addResources('es-AR', 'TrackingInfo', {
  emailPlaceholder: 'Email',
  subtitle: 'Código de seguimiento ',
  buttonLabel: 'Consultar otro envío',
  noStateLabel: 'Entendido',
  noStateTitle: 'Tu paquete no comenzó el recorrido',
  noStateMessage: 'Por favor, intentá nuevamente más tarde.',
  subscribeTitle: '¡Suscribite!',
  subscribeMessage: 'Recibí todas las actualizaciones de tu pedido',
  privacyPolicy: `Seleccionando "Enviar" estás aceptando los términos y condiciones del servicio`,
  successMessage: '¡Te suscribiste correctamente!',
  successInfo:
    'A partir de ahora recibirás todas las actualizaciones de tu pedido',
});

i18next.addResources('es-CL', 'TrackingInfo', {
  emailPlaceholder: 'Email',
  subtitle: 'Código de seguimiento ',
  buttonLabel: 'Consultar otro envío',
  noStateLabel: 'Entendido',
  noStateTitle: 'Tu paquete no comenzó el recorrido',
  noStateMessage: 'Por favor, intenta nuevamente más tarde.',
  subscribeTitle: '¡Suscribite!',
  subscribeMessage: 'Recibí todas las actualizaciones de tu pedido',
  privacyPolicy: `Seleccionando "Enviar" estás aceptando los términos y condiciones del servicio`,
  successMessage: '¡Te suscribiste correctamente!',
  successInfo:
    'A partir de ahora recibirás todas las actualizaciones de tu pedido',
});

i18next.addResources('es-CO', 'TrackingInfo', {
  emailPlaceholder: 'Email',
  subtitle: 'Código de seguimiento ',
  buttonLabel: 'Consultar otro envío',
  noStateLabel: 'Entendido',
  noStateTitle: 'Tu paquete no comenzó el recorrido',
  noStateMessage: 'Por favor, intenta nuevamente más tarde.',
  subscribeTitle: '¡Suscribite!',
  subscribeMessage: 'Recibí todas las actualizaciones de tu pedido',
  privacyPolicy: `Seleccionando "Enviar" estás aceptando los términos y condiciones del servicio`,
  successMessage: '¡Te suscribiste correctamente!',
  successInfo:
    'A partir de ahora recibirás todas las actualizaciones de tu pedido',
});

i18next.addResources('es-MX', 'TrackingInfo', {
  emailPlaceholder: 'Email',
  subtitle: 'Código de seguimiento ',
  buttonLabel: 'Consultar otro envío',
  noStateLabel: 'Entendido',
  noStateTitle: 'Tu paquete no comenzó el recorrido',
  noStateMessage: 'Por favor, intenta nuevamente más tarde.',
  subscribeTitle: '¡Suscribite!',
  subscribeMessage: 'Recibí todas las actualizaciones de tu pedido',
  privacyPolicy: `Seleccionando "Enviar" estás aceptando los términos y condiciones del servicio`,
  successMessage: '¡Te suscribiste correctamente!',
  successInfo:
    'A partir de ahora recibirás todas las actualizaciones de tu pedido',
});

i18next.addResources('es-PE', 'TrackingInfo', {
  emailPlaceholder: 'Email',
  subtitle: 'Código de seguimiento ',
  buttonLabel: 'Consultar otro envío',
  noStateLabel: 'Entendido',
  noStateTitle: 'Tu paquete no comenzó el recorrido',
  noStateMessage: 'Por favor, intenta nuevamente más tarde.',
  subscribeTitle: '¡Suscribite!',
  subscribeMessage: 'Recibí todas las actualizaciones de tu pedido',
  privacyPolicy: `Seleccionando "Enviar" estás aceptando los términos y condiciones del servicio`,
  successMessage: '¡Te suscribiste correctamente!',
  successInfo:
    'A partir de ahora recibirás todas las actualizaciones de tu pedido',
});

i18next.addResources('es-UY', 'TrackingInfo', {
  emailPlaceholder: 'Email',
  subtitle: 'Código de seguimiento ',
  buttonLabel: 'Consultar otro envío',
  noStateLabel: 'Entendido',
  noStateTitle: 'Tu paquete no comenzó el recorrido',
  noStateMessage: 'Por favor, intentá nuevamente más tarde.',
  subscribeTitle: '¡Suscribite!',
  subscribeMessage: 'Recibí todas las actualizaciones de tu pedido',
  privacyPolicy: `Seleccionando "Enviar" estás aceptando los términos y condiciones del servicio`,
  successMessage: '¡Te suscribiste correctamente!',
  successInfo:
    'A partir de ahora recibirás todas las actualizaciones de tu pedido',
});
