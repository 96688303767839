import { useCallback, useEffect, useState } from 'react';

const MOBILE_WIDTH = 780;
const LAPTOP_WIDTH = 1280;

const useIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = useCallback(() => setWidth(window.innerWidth), []);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [updateWidth]);

  return [width <= MOBILE_WIDTH, width <= LAPTOP_WIDTH];
};

export default useIsMobile;
