import App from '@/app/App';
import store from '@/redux/store';
import * as Sentry from '@sentry/react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

Sentry.init({
  dsn: process.env.VITE_SENTRY_DSN,
  environment: process.env.VITE_enviroment,
});

ReactDOM.render(
  <Provider store={store}>
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </Provider>,
  document.getElementById('app'),
);
