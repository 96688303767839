import TrackingServices from '@/services/TrackingServices';
import React, { useEffect, useState } from 'react';
import styles from './banner.module.scss';
import { BannerProps } from './banner.types';

const Banner: React.FC<BannerProps> = ({ bannerTag, retailerToken }) => {
  const [img, setImg] = useState('');
  const [url, setUrl] = useState('');
  useEffect(() => {
    TrackingServices.banner(bannerTag, retailerToken).then((response) => {
      setImg(response.data.data);
      setUrl(response.data.url);
    });
  }, [url, bannerTag, retailerToken]);
  return (
    <div className={styles.banner}>
      {url !== '' ? (
        <a href={url} target="_blank" rel="noreferrer">
          <img src={img} alt="" />
        </a>
      ) : (
        <img src={img} alt="" />
      )}
    </div>
  );
};

export default Banner;
