import { useEffect } from 'react';

const useRecaptchaV3 = () => {
  useEffect(() => {
    const recaptchaCss = `<style>.grecaptcha-badge {
      visibility: hidden;
    }</style>`;
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.VITE_RECAPTCHA_KEY_V3}`;
    document.body.appendChild(script);
    document.head.insertAdjacentHTML('beforeend', recaptchaCss);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useRecaptchaV3;
