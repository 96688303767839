export enum States {
  Active,
  Cancel,
  Damaged,
}

export enum Workflows {
  Dispatch = 'dispatch',
  Dispatch20 = 'dispatch20',
  Refund = 'refund',
  SameDay = 'sameDay',
}

export enum OperationType {
  Point = 1,
  Home = 2,
  RefundPoint = 3,
  RefundHome = 4,
}

export const WorkflowTooltips: { [key: string]: any } = {
  [Workflows.Dispatch]: {
    [OperationType.Point]: 'tooltipDispatchPoint',
    [OperationType.Home]: 'tooltipDispatchHome',
  },
  [Workflows.Refund]: {
    [OperationType.Point]: 'tooltipRefundPoint',
    [OperationType.Home]: 'tooltipRefundHome',
    [OperationType.RefundPoint]: 'tooltipRefundPoint',
    [OperationType.RefundHome]: 'tooltipRefundHome',
  },
  [Workflows.SameDay]: 'tootltipSameday',
  [Workflows.Dispatch20]: 'tooltipDispatch20',
};

export const WorkflowTooltipsMobile: { [key: string]: any } = {
  [Workflows.Dispatch]: {
    [OperationType.Point]: 'tooltipDispatchPointMobile',
    [OperationType.Home]: 'tooltipDispatchHomeMobile',
  },
  [Workflows.Refund]: {
    [OperationType.Point]: 'tooltipRefundPointMobile',
    [OperationType.Home]: 'tooltipRefundHomeMobile',
    [OperationType.RefundPoint]: 'tooltipRefundPointMobile',
    [OperationType.RefundHome]: 'tooltipRefundHomeMobile',
  },
  [Workflows.SameDay]: 'tootltipSamedayMobile',
  [Workflows.Dispatch20]: 'tooltipDispatch20Mobile',
};
