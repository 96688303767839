import NewStepper from '@/app/components/NewStepper/NewStepper';
import { PAGE_LOAD } from '@/constants/dataLayer';
import {
  inputNumberSelector,
  pickitNumberSelector,
  titleSelector,
  tokenSelector,
  trackingNumberSelector,
  trackingSelector,
  transactionSelector,
} from '@/redux/tracking/selectors';
import pushDataLayer from '@/utils/dataLayer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styles from './trackingInfo.module.scss';
import './trackingInfo.i18n';

function getHomeUrl(token: string) {
  const url = new URL(document.location.href);
  if (token) url.searchParams.append('token', token);

  return `/${url.search}`;
}

const TrackingInfo: React.FC = () => {
  const [t] = useTranslation();
  const title = useSelector(titleSelector);
  const trackingNumber = useSelector(trackingNumberSelector);
  const pickitNumber = useSelector(pickitNumberSelector);
  const tracking = useSelector(trackingSelector);
  const transaction = useSelector(transactionSelector);
  const inputNumber = useSelector(inputNumberSelector);
  const token = useSelector(tokenSelector);

  useEffect(() => {
    pushDataLayer(PAGE_LOAD);
  }, []);

  getHomeUrl(token);
  if (!pickitNumber) return <Redirect to={getHomeUrl(token)} />;

  return (
    <div className={styles.trackingInfo}>
      <div className={styles.title}>
        <h1>{title}</h1>
        <p className={styles.trackingCode}>
          {t('TrackingInfo:subtitle')}
          <span>{inputNumber}</span>
        </p>
      </div>
      <div className={styles.trackingDesktop}>
        <div className={styles.trackingContainer}>
          <NewStepper
            transaction={transaction}
            trackingNumber={trackingNumber}
            steps={tracking}
          />
        </div>
      </div>

      <a className={styles.button} href={getHomeUrl(token)}>
        {t('TrackingInfo:buttonLabel')}
      </a>
    </div>
  );
};

export default TrackingInfo;
